<template>
  <v-container fluid>
    <div>
      <!-- le workflow applicatif -->
      <div class="d-flex justify-center" flat tile>
        <Workflow
          width="600"
          height="180"
          :steps="workflowSteps"
          :currentStep="workflowIndex"
          :labelWidth="200"
          :lineWidth="140"
        ></Workflow>
      </div>

      <v-row justify="center">
        <v-col xl="6" lg="8" md="8" sm="10" xs="12">
          <!-- le titre et le bouton retour -->
          <div class="d-flex justify-center">
            <TitleAndReturnComponent title="Intitulé poste" />
          </div>

          <!-- la barre de progression -->
          <v-row justify="center" no-gutters>
            <v-col xl="6" lg="8" md="8" sm="10" xs="12">
              <!-- la progess bar à afficher lors du chargement des données -->
              <v-progress-linear
                indeterminate
                :active="loading"
              ></v-progress-linear>
            </v-col>
          </v-row>

          <!-- l'affichage de la table -->
          <v-card flat outlined class="my-4">
            <v-card-title class="font-weight-regular">
              <v-row no-gutters justify="space-between">
                <div>Ajouter un intitulé poste</div>
              </v-row>
            </v-card-title>

            <!-- le métier rome -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">alias ROME</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="L'alias"
                    dense
                    v-model="alias"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- les grands domaines rome -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">grand domaine ROME</div>
                </v-col>
                <v-col cols="7" align-self="start">
                  <v-autocomplete
                    :items="romeHightDomains"
                    item-text="label"
                    v-model="selectedRomeHightDomains"
                    no-data-text="aucun grand domaine ROME"
                    return-object
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- La définition -->
            <v-card-text>  
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="start">
                  <div class="label-field capitalize">définition (mini-fiche de poste)</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-textarea
                    placeholder="La définition"
                    dense
                    v-model="definition"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- Accès emploi métier -->
            <v-card-text>  
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="start">
                  <div class="label-field capitalize">accès emploi métier</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-textarea
                    placeholder="Les prérequis poste"
                    dense
                    v-model="acces"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- Les compétences de base -->
            <v-card-text>  
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="start">
                  <div class="label-field capitalize">compétences de base</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-textarea
                    placeholder="Les compétences de base"
                    dense
                    v-model="basicSkills"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                class="ma-2 px-4 btn"
                color="primary"
                :disabled="!canSave"
                @click="save"
                >Enregistrer</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";

import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";

import { RomeHightDomainsService } from "@/service/dictionary/rome_hight_domains_service.js";
import { InternalJobsService } from "@/service/dictionary/internal_jobs_service.js";

import * as exceptions from "@/service/exception_to_message.js";

export default {
  name: "AddJobTitle",
  components: { Workflow, TitleAndReturnComponent },

  mixins: [WorkflowMixin, RoleMixin, TableViewEditWorkflowMixin, SnackBarMixin],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /**le service d'accès au APIs. */
      serviceInternalJobs: null,
      serviceRomeHightDomains: null,

      /** le nom */
      alias: null,
      /** la définition */
      definition: null,
      /** les accès métier */
      acces: null,
      /** Les compétences de base */
      basicSkills: null,

      /** La liste des grands domaines ROME */
      romeHightDomains: [],
      /** Le grnd domain rome sélectionné */
      selectedRomeHightDomains: null,
    };
  },
  methods: {

    /** Chargement des données pour le vue */
    async load() {
      try {
        this.loading = true;

        // Récupération des grand domaine rome
        this.romeHightDomains = await this.serviceRomeHightDomains.getAll();

      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
    
    /** Sauvegarde des données */ 
    async save() {

      // enregistrement 
      if (this.entityHasChanged) {
        try {
          let entity = {};
          entity.romeHightDomainCode = this.selectedRomeHightDomains.code;
          entity.alias = this.alias;
          entity.definition = this.definition;
          entity.acces = this.acces;
          entity.basicSkills = this.basicSkills;

          // création de l'entité en base
          await this.serviceInternalJobs.create(entity);

          this.$router.go(-1);

        } catch (error) {
          console.error(error);
          this.addErrorToSnackbar(
            "enregistrement du métier interne : " +
              (exceptions.toMessage(error) || "problème technique")
          );
        }
      }
    },

  },
  computed: {
    canSave() {
      try {
        if (this.selectedRomeHightDomains == null) {
          return false;
        }

        let changed = false;

        if (this.entityHasChanged) {
          changed = true;
        }

        return changed;
      } catch (error) {
        console.error(error);
        return false;
      }
    },

    /**retourne true si entité est modifié */
    entityHasChanged() {
      let changed = false;

      if (this.alias) {
        changed = true;
      }

      return changed;
    },   

  }, // END COMPUTED

  mounted() {
    //instanciation des services d'accès aux APIs
    this.serviceRomeHightDomains = new RomeHightDomainsService(this.$api.getRomeHightDomainsApi());
    this.serviceInternalJobs = new InternalJobsService(this.$api.getInternalJobsApi());

    // Initialisation du worflow
    this.initTableViewEditWorkflow(this.canEdit, 1);

    this.load();

  },
};
</script>

<style>
</style>